import React, { useEffect, useContext, useReducer } from 'react'
import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  TOGGLE_CART_ITEM_AMOUNT,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  SET_SHIPPING_FEES
} from '../actions'
import cart_reducer from '../reducers/cart_reducer'
import { useProductsContext } from './products_context';


const getLocalStroage = () => {
  let cart = localStorage.getItem('cart');
  if (cart) {
    return JSON.parse(cart);
  }
  else {
    return [];
  }
}



const initialState = {
  cart: getLocalStroage(),
  total_items: 0,
  total_amount: 0,
  shipping_fees: 0,
}

const CartContext = React.createContext()

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cart_reducer, initialState);
  const { settings } = useProductsContext();


  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(state.cart));
    dispatch({ type: COUNT_CART_TOTALS });

  }, [state.cart])

  useEffect(() => {
    dispatch({ type: SET_SHIPPING_FEES, payload: settings.shippingFees });
  }, [settings])


  // add to cart 
  const addToCart = (id, color, size, amount, product) => {
    console.log(id, color, size, amount, product);
    dispatch({ type: ADD_TO_CART, payload: { id, color, size, amount, product } });
  }

  const removeItem = (id) => {
    dispatch({ type: REMOVE_CART_ITEM, payload: id });

  }

  const toggleAmount = (id, value) => {
    dispatch({ type: TOGGLE_CART_ITEM_AMOUNT, payload: { id, value } });

  }

  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  }




  return (
    <CartContext.Provider value={{ ...state, addToCart, removeItem, clearCart, toggleAmount }}>{children}</CartContext.Provider>
  )
}
// make sure use
export const useCartContext = () => {
  return useContext(CartContext)
}
