import {
  LOAD_PRODUCTS,
  SET_LISTVIEW,
  SET_GRIDVIEW,
  UPDATE_SORT,
  SORT_PRODUCTS,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
} from '../actions'

const filter_reducer = (state, action) => {


  if (action.type === CLEAR_FILTERS) {
    return {
      ...state, filters: {
        ...state.filters,
        text: '',
        company: 'tous',
        category: 'tous',
        color: 'tous',
        size: 'tous',
        price: state.filters.max_price,
        shipping: false
      }
    }
  }

  if (action.type === LOAD_PRODUCTS) {
  

    // get all product with stock 
    let all_products = [...action.payload];
    // all_products = all_products.filter((p) => p.stockItems.length > 0)

    let all_prices = all_products.map((p) => p.price)
    let maxPrice = Math.max(...all_prices);
    let minPrice = Math.min(...all_prices);

    return {
      ...state,
      all_products: [...all_products],
      filtered_product: [...action.payload],
      filters: { ...state.filters, max_price: maxPrice, price: maxPrice, min_price: minPrice }
    }
  }

  if (action.type === SET_LISTVIEW) {
    return { ...state, grid_view: false }
  }

  if (action.type === SET_GRIDVIEW) {
    return { ...state, grid_view: true }
  }

  if (action.type === UPDATE_SORT) {
    return { ...state, sort: action.payload }
  }

  if (action.type === SORT_PRODUCTS) {

    const { sort, filtered_product } = state;
    let tempProducts = [...filtered_product];

    if (sort === "price-lowest") {
      tempProducts = tempProducts.sort((a, b) => a.price - b.price)
    }
    if (sort === "price-highest") {
      tempProducts = tempProducts.sort((a, b) => b.price - a.price)

    }
    if (sort === "name-a") {
      tempProducts = tempProducts.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })

    }
    if (sort === "name-z") {
      tempProducts = tempProducts.sort((a, b) => {
        return b.name.localeCompare(a.name);
      })
    }



    return { ...state, filtered_product: tempProducts }
  }

  if (action.type === UPDATE_FILTERS) {
    const { name, value } = action.payload;
    // console.log(action);
    return { ...state, filters: { ...state.filters, [name]: value } }
  }

  if (action.type === FILTER_PRODUCTS) {

    const { all_products } = state;
    const { text, category, color, size, price, shipping, company } = state.filters;
    let tempProducts = [...all_products];



    if (text)
      tempProducts = tempProducts.filter((prod) => prod.name.toLowerCase().indexOf(text.toLowerCase()) > -1);

    if (company !== "tous")
      tempProducts = tempProducts.filter((prod) => prod.company === company);

    if (category !== "tous")
      tempProducts = tempProducts.filter((prod) => prod.category?.name.toLowerCase().indexOf(category.toLowerCase()) > -1);

    if (price)
      tempProducts = tempProducts.filter((prod) => prod.price <= price);

    if (color !== "tous")
      tempProducts = tempProducts.filter((prod) => {
        console.log(prod);

        return prod.stockItems.some((c) => {
          // console.log(c);
          console.log(size);

          if (size === "tous")
            return c.quantity > 0 && c.color_id === color
          else
            return c.quantity > 0 && c.color_id === color && c.size_id === size

        })
        // return prod.colors.some((c) => {
        //   // console.log(c);
        //   return c._id === color
        // })

      });
    if (size !== "tous")
      tempProducts = tempProducts.filter((prod) => {

        // return prod.size.some((s) => {
        //   // console.log(c);
        //   return s._id === size
        // })



        return prod.stockItems.some((c) => {
          // console.log(c);
          console.log(color);
          if (color === "tous")
            return c.quantity > 0 && c.size_id === size;
          else
            return c.quantity > 0 && c.size_id === size && c.color_id === color;

        })
      });


    return { ...state, filtered_product: tempProducts };
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default filter_reducer
