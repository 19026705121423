import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatPrice, getUniqueValues, isWhite } from '../utils/helpers'
import { FaCheck, FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { API_URL } from '../utils/constants'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useFilterContext } from '../context/filter_context'
import blur from './../assets/blur.png'

const Product = ({ _id, image, image_small, name, price, colors, showColors, images, stockItems }) => {

  const [imagesFilters, setImagesFilters] = useState(images);
  const [mainImage, setMainImage] = useState(image);
  const [blurImage, setBlurImage] = useState(image_small || blur);
  const [mainColor, setMainColor] = useState();

  const [inStockColors, setInStockColors] = useState([]);

  const { filters: { color } } = useFilterContext();


  useEffect(() => {

    const stock_colors = stockItems.map((i) => (i.quantity > 0 ? i.color_id : null)).filter(n => n)
    setInStockColors(colors.filter((c) => stock_colors.includes(c._id)));

  }, [colors])

  useEffect(() => {
    const inStockColors_ids = inStockColors.map((i) => i._id);
    const image_with_in_stock_colors = images.filter((i) => inStockColors_ids.includes(i.color_id));

    // console.log(images);
    // console.log(image_with_in_stock_colors);

    if (image_with_in_stock_colors.length > 0)
      setImagesFilters([...image_with_in_stock_colors])
    else
      setImagesFilters([{ url: image, small: image_small }])

  }, [inStockColors])

  useEffect(() => {
    // console.log(imagesFilters);
    setMainImage(imagesFilters[0].url)
    setBlurImage(imagesFilters[0].small || blur)

  }, [imagesFilters])

  // Search Filter Color 
  useEffect(() => {

    if (color) {
      const img_color = imagesFilters.find((img) => img?.color_id === color);
      if (img_color) {
        setMainImage(img_color.url);
        setBlurImage(img_color.small)
      }
    }
  }, [color])

  // Product Result  Color Switch 
  useEffect(() => {

    if (mainColor) {
      const img_color = imagesFilters.find((img) => img?.color_id === mainColor._id);
      if (img_color) {
        setMainImage(img_color.url);
        setBlurImage(img_color.small)
      }
    }
  }, [mainColor])


  return <Wrapper>
    <div className="container">
      {/* placeholderSrc={blur}  */}
      {
        <LazyLoadImage wrapperClassName="gallery-img-wrapper" effect="blur" placeholderSrc={`${API_URL}${blurImage}`} src={`${API_URL}${mainImage}`} alt={name} />
      }
      <Link to={`/products/${_id}`} className="link">
        <FaSearch />
      </Link>
    </div>
    <footer>
      {
        showColors &&
        <div className="colors">
          {
            inStockColors.map((c, index) => {
              return <button onClick={() => setMainColor(c)} key={index} name="color" style={{ background: c?.code }} value={c?._id} className='color-btn'>
                {mainColor && mainColor._id == c?._id ? <FaCheck className={`${isWhite(c?.code) ? "black-check" : null}`} /> : null}
              </button>
            })
          }
        </div>
      }
      <h5>{name}</h5>
      <p>{formatPrice(price)}</p>
    </footer>
  </Wrapper>
}

const Wrapper = styled.article`
 .black-check {
    color: #000000 !important;
  }
  .gallery-img-wrapper{
    display: block !important;
  }

  .container {
    position: relative;
    background: var(--clr-black);
    border-radius: var(--radius);
  }
  img {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: var(--radius);
    transition: var(--transition);
  }
  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--clr-primary-5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: var(--clr-white);
    }
  }
  .container:hover img {
    opacity: 0.5;
  }
  .container:hover .link {
    opacity: 1;
  }
  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer h5,
  footer p {
    margin-bottom: 0;
    font-weight: 400;
  }

  footer p {
    color: var(--clr-primary-5);
    letter-spacing: var(--spacing);
  }

  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
 
  .color-btn {
    display: inline-block;
    border: 1px solid var(--clr-grey-7);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
`
export default Product
