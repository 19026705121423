export const formatPrice = (number) => {
    const newNumber = Intl.NumberFormat('tn-TN', {
        style: 'currency',
        currency: 'TND'
    }).format(number);

    return newNumber;

}

export const getUniqueValues = (data, type, subtype, is_object) => {

    let all_items = { _id: false, name: "tous" }
    if (!subtype) {
        subtype = 0;
        all_items = all_items.name;

    }
    let flags = {}
    let unique = data.map((item) => {

        if (is_object) {
            // console.log(item[type]);
            let unique_sub_items = item[type].map((si) => {
                // console.log(si);
                if (!flags[si[subtype]]) {
                    flags[si[subtype]] = true;

                    return { ...si };
                }
            })
            return unique_sub_items;

        } else if (!flags[item[type][subtype]]) {
            flags[item[type][subtype]] = true;
            return item[type];
        }
    }).flat().filter(v => v)

    // console.log(unique);

    // if (subtype) {
    //     unique = unique.flat();
    //     unique = unique.map((item) => {
    //         if (typeof item !== undefined && item && typeof (item[subtype]) !== undefined && item[subtype] !== null) {
    //             // return item[subtype]
    //             return [item[subtype], item]
    //         }
    //     }).filter((i) => i !== undefined)
    // }
    // console.log(unique);

    return [all_items, ...new Set(unique.sort())]
}

export const isWhite = (str) => {
    return computeLuminence(str) > 0.179;
}




function computeLuminence(backgroundcolor) {
    var colors = hexToRgb(backgroundcolor);

    var components = ['r', 'g', 'b'];
    for (var i in components) {
        var c = components[i];

        colors[c] = colors[c] / 255.0;

        if (colors[c] <= 0.03928) {
            colors[c] = colors[c] / 12.92;
        } else {
            colors[c] = Math.pow(((colors[c] + 0.055) / 1.055), 2.4);
        }
    }

    var luminence = 0.2126 * colors.r + 0.7152 * colors.g + 0.0722 * colors.b;

    return luminence;
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
