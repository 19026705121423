import React from 'react'
import styled from 'styled-components'
import { useCartContext } from '../context/cart_context'
import { Link } from 'react-router-dom'
import { CartContent, EmptyCart, PageHero } from '../components'

const CartPage = () => {


  const { cart } = useCartContext();
  if (cart.length < 1) {
    return <Wrapper className='page-100'>
      <EmptyCart />
    </Wrapper>
  }
  return <main>
    <PageHero title="Panier" />
    <Wrapper>
      <CartContent />
    </Wrapper>

  </main>
}

const Wrapper = styled.main`
  .empty {
    text-align: center;
    h2 {
      margin-bottom: 1rem;
      text-transform: none;
    }
  }
`

export default CartPage
