import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import heroBcg from '../assets/logo.png'
import heroBcg2 from '../assets/new_collections.png'
import { useProductsContext } from '../context/products_context'

const Hero = () => {


  const { settings } = useProductsContext();
  const [websiteTitle, setWetbsiteTitle] = useState();

  useEffect(() => {
    setWetbsiteTitle(settings.name);


  }, [settings])


  return <Wrapper className='section-center'>

    <article className='content'>

      <h1>
        {websiteTitle} <br />
        comfort zone
      </h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda corporis quae error explicabo, laborum voluptas laudantium perspiciatis praesentium rem sequi eum, aut temporibus provident vero incidunt itaque unde quibusdam quas.
      </p>
      <Link to="/products" className='btn hero-btn'>
        Shop Now
      </Link>
    </article>
    <article className='img-container'>
      <img src={heroBcg} alt="" className='main-img' />
      {/* <img src={heroBcg2} alt="" className='accent-img' /> */}

    </article>
  </Wrapper>
}

const Wrapper = styled.section`
   min-height: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 5rem;
  .img-container {
    display: none;
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
    color: var(--clr-grey-5);
    font-size: 1rem;
  }
  @media (min-width: 992px) {
    /* height: calc(100vh - 5rem); */
    /* height: calc(100vh - 15rem); */
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    h1 {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
    .img-container {
      display: block;
      position: relative;
    }
    .main-img {
      width: 100%;
      height: 550px;
      position: relative;
      border-radius: var(--radius);
      display: block;
      object-fit: cover;
    }
    .accent-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 250px;
      transform: translateX(-50%);
      border-radius: var(--radius);
    }
    .img-container::before {
      content: '';
      position: absolute;
      width: 10%;
      height: 80%;
      background: var(--clr-primary-7);
      bottom: 0%;
      left: -8%;
      border-radius: var(--radius);
    }
  }
`

export default Hero
