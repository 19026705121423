import React from 'react'
import { Helmet } from "react-helmet";

function HelmetMeta({ title, description, image, url, type, product_id, price }) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <link rel="canonical" href={url} />
            <meta name="description" content={description} />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content={type} />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            {type === "product" && <meta property="product:retailer_item_id" content={product_id} />}
            {type === "product" && <meta property="product:price:amount" content={price} />}
            {type === "product" && <meta property="product:price:currency" content="TND" />}
            {type === "product" && <meta property="product:availability" content="last_remaining_items" />}




            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
        </Helmet>
    )
}

export default HelmetMeta