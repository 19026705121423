import React from 'react'
import styled from 'styled-components'
import { EmptyCart, PageHero, StripeCheckout } from '../components'
// extra imports
import { useCartContext } from '../context/cart_context'

const CheckoutPage = () => {
  const { cart } = useCartContext();

  return <main>
    <PageHero title="checkout" />
    <Wrapper className='page'>
      {
        cart.length < 1 ? <EmptyCart /> : <StripeCheckout />
      }
    </Wrapper>
  </main>
}
const Wrapper = styled.div`
display:flex ;
align-items: center;
justify-content:center ;
`
export default CheckoutPage
