import React from 'react'
import { useFilterContext } from '../context/filter_context'
import GridView from './GridView'
import ListView from './ListView'

const ProductList = () => {
  //get filtered products to the alias products
  const { filtered_product: products, grid_view } = useFilterContext();

  if (products.length < 1) {
    return <h5 style={{ textTransform: 'none' }}>Désolé, aucun produit ne correspond à votre recherche. </h5>
  }
  if (grid_view === false) {
    return <ListView products={products}></ListView>
  } else {
    return <GridView products={products}></GridView>

  }

}

export default ProductList
