import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatPrice, isWhite } from '../utils/helpers'
import { FaCheck, FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { API_URL } from '../utils/constants'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useFilterContext } from '../context/filter_context'
// import blur from './../assets/blur.png'

const ProductListView = ({ _id, image, name, price, colors, images, description,stockItems }) => {


  const [mainImage, setMainImage] = useState(image);
  const [mainColor, setMainColor] = useState();
  const [inStockColors, setInStockColors] = useState([]);

  const { filters: { color } } = useFilterContext();


  useEffect(() => {

    const stock_colors = stockItems.map((i) => (i.quantity > 0 ? i.color_id : null)).filter(n => n)
    setInStockColors(colors.filter((c) => stock_colors.includes(c._id)));

  }, [colors])

  // Search Filter Color 
  useEffect(() => {

    if (color) {
      const img_color = images.find((img) => img?.color_id === color);
      if (img_color)
        setMainImage(img_color.url);
    }
  }, [color])

  // Product Result  Color Switch 
  useEffect(() => {

    if (mainColor) {
      const img_color = images.find((img) => img?.color_id === mainColor._id);
      if (img_color)
        setMainImage(img_color.url);
    }
  }, [mainColor])


  return <Wrapper>

    <article key={_id}>
      <img src={`${API_URL}${mainImage}`} alt={name} />
      <div>
        <h4>{name}</h4>
        <div className="colors">
          {
            inStockColors.map((c, index) => {
              return <button onClick={() => setMainColor(c)} key={index} name="color" style={{ background: c.code }} value={c.code} className='color-btn'>
                {mainColor && mainColor._id == c?._id ? <FaCheck className={`${isWhite(c?.code) ? "black-check" : null}`} /> : null}
              </button>
            })
          }
        </div>
        <h5 className='price'>{formatPrice(price)}</h5>
        <p>{description.substring(0, 150)}...</p>

        <Link to={`/products/${_id}`} className="btn">Détials</Link>
      </div>

    </article>

  </Wrapper>
}

const Wrapper = styled.article`
 display: grid;
  row-gap: 3rem;

  img {
    width: 100%;
    display: block;
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: var(--radius);
    margin-bottom: 1rem;
  }
  h4 {
    margin-bottom: 0.5rem;
  }
  .price {
    color: var(--clr-primary-5);
    margin-bottom: 0.75rem;
  }
  p {
    max-width: 45em;
    margin-bottom: 1rem;
  }
  .btn {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  
  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
 
 
  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
 
  .color-btn {
    display: inline-block;
    border: 1px solid var(--clr-grey-7);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  @media (min-width: 992px) {
    article {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 2rem;
      align-items: center;
    }
  }
 .black-check {
    color: #000000 !important;
  }
 
`
export default ProductListView
