
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const EmptyCart = () => {
    return (
        <Wrapper className='page'>
            <div className="empty">
                <h2>Votre panier est vide</h2>
                <Link to="/products" className='btn'>
                    Acheter les offres du jour
                </Link>
            </div>
        </Wrapper>
    );
}


const Wrapper = styled.main`
  .empty {
    text-align: center;
    h2 {
      margin-bottom: 1rem;
      text-transform: none;
    }
  }
`

export default EmptyCart;