import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useProductsContext } from '../context/products_context';
import { API_URL } from '../utils/constants';

const ProductImages = ({ images = [{ url: '', color_id: '' }], stockItems }) => {
  console.log(images);
  const [main, setMain] = useState(images[0]);
  const [imagesFilters, setImagesFilters] = useState(images);
  const { selected_color } = useProductsContext();

  useEffect(() => {

    if (selected_color) {
      //set only the images related to the selected color 
      // setImagesFilters(images.filter((i) => i.color_id === selected_color._id))
      const get_colors_with_stock = stockItems.filter((i) => i.quantity > 0).map((i) => i.color_id);
      setImagesFilters(images.filter((i) => get_colors_with_stock.includes(i.color_id)))
    }

    if (selected_color) {
      const img_color = imagesFilters.find((img) => img?.color_id === selected_color._id);
      if (img_color)
        setMain(img_color);
    }
  }, [selected_color])

  return <Wrapper>
    <img src={`${API_URL}${main?.url}`} alt={main?.filename} className='main' />
    <div className="gallery">
      {/* {imagesFilters.map((image, index) => { */}
      {imagesFilters.map((image, index) => {
        if (image?.url)
          return <img
            className={`${image?.url === main?.url ? 'active' : null}`}
            src={`${API_URL}${image?.url}`} alt={image?.filename} key={index} onClick={() => setMain(image)} />
      })}
    </div>
  </Wrapper>
}

const Wrapper = styled.section`
  .main {
    height: 600px;
  }
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    object-fit: cover;
  }
  .gallery {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: 1rem;
    img {
      height: 100px;
      cursor: pointer;
      margin-bottom: 1rem;
    }
  }
  .active {
    box-shadow: 0px 0px 0px 2px var(--clr-primary-5);
  }
  @media (max-width: 576px) {
    .main {
      height: 300px;
    }
    .gallery {
      img {
        height: 50px;
      }
    }
  }
  @media (min-width: 992px) {
    .main {
      height: 500px;
    }
    .gallery {
      img {
        height: 75px;
      }
    }
  }
`

export default ProductImages
