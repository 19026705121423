import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FaCheck } from 'react-icons/fa'
import { useCartContext } from '../context/cart_context'
import AmountButtons from './AmountButtons'
import { isWhite } from '../utils/helpers'
import { useProductsContext } from '../context/products_context'
import ReactPixel from 'react-facebook-pixel';

const AddToCart = ({ product }) => {
  const { _id: id, colors, stockItems, price,name } = product;
  let { size } = product;

  size = size?.sort((a, b) => (a.name > b.name) ? 1 : -1);

  const { addToCart } = useCartContext();
  const [mainColor, setMainColor] = useState();
  const [mainSize, setMainSize] = useState();
  const [amount, setAmount] = useState(1);
  const [stock, setStock] = useState(1);
  const [inStockColors, setInStockColors] = useState([]);
  const [inStockSizes, setInStockSizes] = useState([]);

  const { setSelectedColor } = useProductsContext();


  useEffect(() => {

    const stock_colors = stockItems?.map((i) => (i.quantity > 0 ? i.color_id : null)).filter(n => n)
    setInStockColors(colors?.filter((c) => stock_colors.includes(c._id)));
  }, [colors])


  useEffect(() => {
    setMainColor(inStockColors[0]);
  }, [inStockColors])



  useEffect(() => {
    setSelectedColor(mainColor);
    const stock_sizes = stockItems?.map((i) => ((i?.quantity > 0 && i?.color_id === mainColor?._id) ? i?.size_id : null)).filter(n => n)
    setInStockSizes(size?.filter((c) => stock_sizes.includes(c?._id)));

    const selected_size = stockItems?.find((i) => (i?.color_id === mainColor?._id && i?.size_id === mainSize?._id));
    // if the selected size not exist in the new color sizes we will select the first size 
    if (!selected_size)
      setMainSize(inStockSizes[0]);

  }, [mainColor])


  useEffect(() => {
    setMainSize(inStockSizes[0]);
  }, [inStockSizes])

  useEffect(() => {
    const stock_color_size = stockItems?.find((i) => (i?.color_id === mainColor?._id && i?.size_id === mainSize?._id));
    setStock(stock_color_size?.quantity)
  }, [mainColor, mainSize])


  useEffect(() => {
    setAmount((oldAmount) => {
      if (oldAmount >= stock)
        return stock;
      return Math.max(oldAmount, 1);
    });
  }, [stock])



  const increase = () => {
    setAmount((oldAmount) => {
      return Math.min(oldAmount + 1, stock)
    });
  }

  const decrease = () => {
    setAmount((oldAmount) => {
      return Math.max(1, oldAmount - 1)
    });

  }

  // console.log(product);
  return <Wrapper>
    <div className="colors">
      <span> Couleur : </span>
      <div>
        {
          inStockColors.length > 0 && inStockColors?.map((color, index) => {
            return <button key={index} className={`${mainColor?._id == color?._id ? 'color-btn active' : 'color-btn'}`} style={{ background: color?.code }} title={color?.name} onClick={() => setMainColor(color)} >
              {mainColor?._id == color?._id ? <FaCheck className={`${isWhite(color?.code) ? "black-check" : null}`} /> : null}
            </button>
          })
        }
      </div>
    </div>
    <div className="colors">
      <span> Taille : </span>
      <div className='size-container'>
        {
          inStockSizes.length > 0 && inStockSizes?.map((size, index) => {
            console.log(size);
            return <button key={index} className={`${mainSize?._id == size?._id ? 'size-btn active' : 'size-btn'}`} title={size?.name} onClick={() => setMainSize(size)} >

              {size?.name}
            </button>
          })
        }
      </div>
    </div>


    {
      stock > 0 && <div className="btn-container">
        <AmountButtons amount={amount} increase={increase} decrease={decrease} />
        <Link to="/cart" className='btn' onClick={() => {
          ReactPixel.track("AddToCart", {
            value: price,
            currency: 'TND',
            content_type: 'product', // required property
            contents: [
              {
                id: id,
                quantity: amount
              }
            ]
          });


          addToCart(id, mainColor, mainSize, amount, product);
        }}>
          Ajouter au panier
        </Link>
      </div>}
  </Wrapper>
}

const Wrapper = styled.section`
  margin-top: 2rem;
  .colors {
    display: grid;
    grid-template-columns: 125px 1fr;
    align-items: center;
    margin-bottom: 1rem;
    span {
      text-transform: capitalize;
      font-weight: 700;
    }
    div {
      display: flex;
    }
  }
  .color-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: 1px solid var(--clr-grey-7);
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.75rem;
      color: var(--clr-white);
    }
  }
  .black-check {
    color: #000000 !important;
  }
  .size-container{
    flex-wrap: wrap;
  }
  .size-btn {
    display: inline-block;
    padding: 1rem;
    font-size: 1.10rem;
    min-width: 1.5rem;
    height: 1.5rem;
    border-radius: 17%;
    margin-bottom: 0.2rem;
    background: var(--clr-white);
    margin-right: 0.5rem;
    border: 1px solid var(--clr-grey-7);
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.75rem;
      color: var(--clr-white);
    }
  }
  button.size-btn.active {
    border: 2px solid black;
    background: white;
    color: black;
  } 
  .active {
    opacity: 1;
    border: 1px solid black;
  }
  .btn-container {
    margin-top: 2rem;
  }

  .btn {
    margin-top: 1rem;
    /* width: 140px; */
  }

`
export default AddToCart
