import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ok from './../assets/ok.png'
// import { loadStripe } from '@stripe/stripe-js'
// import {
//   CardElement,
//   useStripe,
//   Elements,
//   useElements,
// } from '@stripe/react-stripe-js'
import axios from 'axios'
import { useCartContext } from '../context/cart_context'
// import { useUserContext } from '../context/user_context'
import { formatPrice } from '../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaHome, FaPhoneAlt, FaRegAddressCard, FaUser } from 'react-icons/fa'
// import CartTotals from './CartTotals'
import { orders_url } from '../utils/constants'
import Loading from './Loading'
// import { Error } from '../pages'
import ReactPixel from 'react-facebook-pixel';

const CheckoutForm = () => {

  const { cart, total_amount, shipping_fees, clearCart, total_items } = useCartContext();

  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState("name");
  const [formDone, setFormDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    ville: "",
    address: "",
  });
  const [formValidity, setFormValidity] = useState({
    name: false,
    phone: false,
    ville: false,
    address: false,
  });

  const refArray = useRef([]);
  const history = useNavigate();

  const sendOrder = async () => {
    setLoading(true);
    try {
      const data = await axios.post(orders_url, {
        customer: formData,
        orderItems: cart, shipping_fees, total_amount, total_items, total: (total_amount + shipping_fees)
      })
      // setSuccess(true);
      setLoading(false);
      setError(false);
      setSuccess(true);
      setTimeout(() => {
        clearCart();
        history("/products", { replace: true })
      }, 10000)
    } catch (error) {
      console.log(error);
      setLoading(false);
      const message = error.response && error.response.data.message ? error.response.data.message : error.message;
      setError(message);
      setFormDone(false)
    }

  }

  // useEffect(() => {
  //   if (formDone) {
  //     setLoading(true);
  //     // sendOrder();
  //     // setLoading(false);
  //     // setFormDone(false);
  //     // setSuccess(true);
  //   }


  // }, [formDone])


  useEffect(() => {
    if (refArray.current[currentTab])
      refArray.current[currentTab].focus();
  }, [refArray, currentTab])

  useEffect(() => {
    if (formDone) {
      sendOrder();
    }
  }, [formDone])

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const nextPrev = (direction) => {

    console.log(currentTab);
    console.log(formValidity[currentTab]);

    let keys = Object.keys(formData);

    if (formValidity[currentTab] && direction > 0) {


      let nextIndex = keys.indexOf(currentTab) + direction;
      let nextItem = keys[nextIndex];

      if (!nextItem) {
        setFormDone(true);
      } else {
        setCurrentTab(nextItem)
        setCurrentTabIndex(nextIndex)
      }
    }
    if (direction < 0) {


      let prevIndex = keys.indexOf(currentTab) + direction;
      let prevItem = keys[prevIndex];
      setCurrentTab(prevItem)
      setCurrentTabIndex(prevIndex)
    }



  }

  if (loading) {
    return <LoadingWrapper><Loading /></LoadingWrapper>
  }
  // if (error) {
  //   return <Error />
  // }

  if (success) {

    ReactPixel.track("Purchase", {
      value: (total_amount + shipping_fees),
      currency: 'TND',
    });
    
    return (
      <div className={`${success && "showme"} thanks-message text-center`} id="text-message">
        <img src={ok} width={100} className="mb-4" />
        <h3>Merci pour votre commande!</h3> <span>Nous allons vous contacter dans les plus brefs délais pour confirmer vos informations !</span>
      </div>
    )
  }



  return (
    <div className="container mt-5">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-8">
          <form id="regForm" onSubmit={(e) => { e.preventDefault(); nextPrev(1) }}>
            <h2 id="register">Passer La Commande</h2>
            <div className={`${success && "hideme"} all-steps`} id="all-steps">
              <span className={`step ${currentTab === "name" ? "active" : ""} ${formValidity.name ? "finish" : ""}`}><FaUser /></span>
              <span className={`step ${currentTab === "phone" ? "active" : ""} ${formValidity.phone ? "finish" : ""}`}><FaPhoneAlt /></span>
              <span className={`step ${currentTab === "ville" ? "active" : ""} ${formValidity.ville ? "finish" : ""}`}><FaHome /></span>
              <span className={`step ${currentTab === "address" ? "active" : ""} ${formValidity.address ? "finish" : ""}`}><FaRegAddressCard /></span>
            </div>
            <div className={`${currentTab === "name" ? "tab showme" : "tab"}`}>
              <h4>Quel est votre Votre Nom ?</h4>
              <p>
                <input ref={ref => { refArray.current["name"] = ref; }} autoFocus placeholder="Votre Nom"
                  onInvalid={e => {
                    e.target.setCustomValidity('Veuillez entrer votre nom');
                    setFormValidity({ ...formValidity, name: false })
                  }}
                  onInput={e => {
                    e.target.setCustomValidity('');
                    if (e.target.checkValidity())
                      setFormValidity({ ...formValidity, name: true })

                  }}
                  name="name" value={formData.name || ""} onChange={handleInput} required={true}
                  disabled={currentTab === "name" ? false : true} /></p>
            </div>
            <div className={`${currentTab === "phone" ? "tab showme" : "tab"}`}>
              <h4>Quel est votre numéro de portable?</h4>
              <p>
                <input ref={ref => { refArray.current["phone"] = ref; }} type="text" placeholder="22XXXXXX"
                  pattern="[0-9]{2}[0-9]{3}[0-9]{3}"
                  onInvalid={e => {
                    console.log(e.target.validity);
                    // if (e.target.validity.valueMissing)
                    e.target.setCustomValidity('Veuillez entrer un numéro de téléphone valide 22XXXXXX');
                    setFormValidity({ ...formValidity, phone: false })
                  }}
                  onInput={e => {
                    e.target.setCustomValidity('');

                    if (e.target.checkValidity())
                      setFormValidity({ ...formValidity, phone: true })
                  }}
                  name="phone" value={formData.phone || ""}
                  required={true}
                  onChange={handleInput}
                  disabled={currentTab === "phone" ? false : true}
                />
              </p>
            </div>
            <div className={`${currentTab === "ville" ? "tab showme" : "tab"}`}>
              <h4>Quelle est Votre Ville?</h4>
              <p><select
                disabled={currentTab === "ville" ? false : true}
                ref={ref => { refArray.current["ville"] = ref; }} className='form-select'
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    nextPrev(1);
                  }
                }}
                onInvalid={e => {
                  e.target.setCustomValidity('Veuillez sélectionner votre ville');
                  setFormValidity({ ...formValidity, ville: false })
                }}
                onChange={e => {
                  handleInput(e);
                  if (e.target.value) {
                    e.target.setCustomValidity('');
                    setFormValidity({ ...formValidity, ville: true })
                  } else {
                    e.target.setCustomValidity('Veuillez sélectionner votre ville');
                    setFormValidity({ ...formValidity, ville: false })
                  }

                }}
                placeholder="Tunis" name="ville" value={formData.ville || ""} required={true}   >
                <option value=""></option>
                <option value="Ariana">Ariana</option>
                <option value="Béja">Béja</option>
                <option value="Ben Arous">Ben Arous</option>
                <option value="Bizerte">Bizerte</option>
                <option value="Gabès">Gabès</option>
                <option value="Gafsa">Gafsa</option>
                <option value="Jendouba">Jendouba</option>
                <option value="Kairouan">Kairouan</option>
                <option value="Kasserine">Kasserine</option>
                <option value="Kébili">Kébili</option>
                <option value="La Manouba">La Manouba</option>
                <option value="Le Kef">Le Kef</option>
                <option value="Mahdia">Mahdia</option>
                <option value="Médenine">Médenine</option>
                <option value="Monastir">Monastir</option>
                <option value="Nabeul">Nabeul</option>
                <option value="Sfax">Sfax</option>
                <option value="Sidi Bouzid">Sidi Bouzid</option>
                <option value="Siliana">Siliana</option>
                <option value="Sousse">Sousse</option>
                <option value="Tataouine">Tataouine</option>
                <option value="Tozeur">Tozeur</option>
                <option value="Tunis">Tunis</option>
                <option value="Zaghouan">Zaghouan</option>
              </select>
              </p>
            </div>
            <div className={`${currentTab === "address" ? "tab showme" : "tab"}`}>
              <h4>Quel est votre Votre Adresse?</h4>
              <p><input
                disabled={currentTab === "address" ? false : true}
                ref={ref => { refArray.current["address"] = ref; }} placeholder="Rue ... Immeuble ... " name="address"
                onInvalid={e => {
                  e.target.setCustomValidity('Veuillez entrer votre adresse');
                  setFormValidity({ ...formValidity, address: false })
                }}
                onInput={e => {
                  e.target.setCustomValidity('');
                  if (e.target.checkValidity())
                    setFormValidity({ ...formValidity, address: true })

                }}

                value={formData.address || ""} required={true} onChange={handleInput} /></p>
            </div>


            <div className={`${success && "hideme"} `} style={{ overflow: 'auto' }} id="nextprevious">
              <div style={{ float: 'right', display: 'flex' }} >
                {currentTabIndex > 0 && <button type="button" id="prevBtn" onClick={() => nextPrev(-1)}><FaAngleDoubleLeft /></button>}
                <button type="submit" id="nextBtn" ><FaAngleDoubleRight /></button> </div>
            </div>
          </form>
          {
            error && <div className='error-box'>
              {error}
            </div>
          }
          <CheckoutBox>
            <div className='checkoutcontainer'>
              <article>
                <h5>Sous-total : <span>{formatPrice(total_amount)}</span></h5>
                <p>
                  Frais de Livraison : <span>{formatPrice(shipping_fees)}</span>
                </p>
                <h5 style={{ display: "block" }}>
                  <span>Paiement a la livraison</span>
                </h5>
                <hr />
                <h4>
                  Total  : <span>{formatPrice(total_amount + shipping_fees)}</span>
                </h4>
              </article>

            </div>
          </CheckoutBox>
        </div>
      </div>


    </div >
  )
}

const StripeCheckout = () => {
  return (
    <Wrapper>
      <CheckoutForm />
    </Wrapper>
  )
}


const LoadingWrapper = styled.section`
.loading{
  margin-top: 0;
}
`
const CheckoutBox = styled.section`
  margin-top: 1rem;
  margin-bottom: 3rem;  display: flex;
  justify-content: center;
  article {
    border: 1px solid var(--clr-grey-8);
    border-radius: var(--radius);
    padding: 1.5rem 3rem;
    width: 100%;
  }
  .checkoutcontainer{
    width:100% ;
  }
  h4,
  h5,
  p {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  p {
    text-transform: capitalize;
  }
  h4 {
    margin-top: 2rem;
  }
  

  @media (min-width: 776px) {
    justify-content: flex-end;
  }
  .btn {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    font-weight: 700;
  }
`


const Wrapper = styled.section`

@media only screen and (max-width: 675px) {
  #register {
    font-size: 1rem !important;
   }
  h4 {
    font-size: 0.8rem;
  }
  form {
    width: 90% !important;
  }
  #regForm {
    padding: 1rem;
  }
}

.error-box {
    background-color: #ffbfbf;
    margin: 0px auto;
    padding: 40px;
    border-radius: 10px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgb(50 50 93 / 10%), 0px 2px 5px 0px rgb(50 50 93 / 10%), 0px 1px 1.5px 0px rgb(0 0 0 / 7%);
    width: 41rem;
    text-align: justify;
    color: red;
}


.hideme{
  display: none !important;
}
.showme{
  display: block !important;
}

#regForm {
    background-color: #ffffff;
    margin: 0px auto;
    padding: 40px;
    border-radius: 10px
}

#register{

  color: var(--clr-primary-1);
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
}

h1 {
    text-align: center
}

input {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    -webkit-appearance: none;
}



.tab input:focus{

  border:1px solid #6a1b9a !important;
  outline: none;
}

input.invalid {
 
    border:1px solid #e03a0666;
}

.tab {
    display: none
}

button {
    background-color: #6A1B9A;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    font-size: 17px;
    cursor: pointer
}

button:hover {
    opacity: 0.8
}

button:focus{

  outline: none !important;
}

#prevBtn {
    background-color: #bbbbbb
}


.all-steps{
      text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.step {
       height: 40px;
    width: 40px;
    margin: 0 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: var(--clr-primary-1);
    opacity: 0.5;
    background-color: #ffffff;
    border: 1px solid var(--clr-primary-1);
}

.step.active {
    opacity: 1
}


.step.finish {
    color: #fff;
    background: var(--clr-primary-1);
    opacity: 1;
}


.all-steps {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px
}

.thanks-message {
    display: none
}

  form {
    width: 100%;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: var(--clr-primary-1);;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
`

export default StripeCheckout
