import {
  ADD_TO_CART,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  REMOVE_CART_ITEM,
  TOGGLE_CART_ITEM_AMOUNT,
  SET_SHIPPING_FEES
} from '../actions'

const cart_reducer = (state, action) => {


  if (action.type === SET_SHIPPING_FEES) {
    return { ...state, shipping_fees: action.payload || 0 }
  }
  if (action.type === ADD_TO_CART) {
    const { id, color, size, amount, product } = action.payload;

    const tempItem = state.cart.find((i) => i.id === id + color._id + size._id)
    // console.log(tempItem);

    if (tempItem) {
      // add to existing item in the cart 
      // state.cart

      const tempCart = state.cart.map((cartItem) => {
        if (cartItem.id === id + color._id + size._id) {
          let newAmount = cartItem.amount + amount;
          if (newAmount > cartItem.max) {
            newAmount = cartItem.max;
          }
          return { ...cartItem, amount: newAmount };
        } else {
          return cartItem;
        }
      })
      // tempItem.amount += amount;
      // console.log(tempItem);
      return { ...state, cart: tempCart }

    } else {
      // create new  item in the cart 
      const newItem = {
        id: id + color._id + size._id,
        name: product.name,
        color: color.code,
        color_id: color._id,
        size: size.name,
        size_id: size._id,
        amount,
        image: product.image,
        price: product.price,
        max: product.stock,
        product_id: product._id
      }
      return { ...state, cart: [...state.cart, newItem] }
    }
  }

  if (action.type === TOGGLE_CART_ITEM_AMOUNT) {
    const { id, value } = action.payload;
    const tempcCart = state.cart.map((cartItem) => {
      console.log(cartItem);

      if (cartItem.id === id) {
        // console.log(cartItem);
        let newAmount;
        if (value === 'inc') {
          newAmount = cartItem.amount + 1;
          if (newAmount > cartItem.max) {
            newAmount = cartItem.max;
          }
        }
        if (value === 'dec') {
          newAmount = cartItem.amount - 1;
          if (newAmount < 1) {
            newAmount = 1;
          }

        }

        return { ...cartItem, amount: newAmount };
      } else {
        return cartItem
      }
    })


    return { ...state, cart: tempcCart }
  }
  if (action.type === REMOVE_CART_ITEM) {
    const itemID = action.payload;
    console.log(itemID);

    const newCart = state.cart.filter((i) => i.id !== itemID);
    return { ...state, cart: newCart };
  }

  if (action.type === CLEAR_CART) {

    return { ...state, cart: [] };
  }

  if (action.type === COUNT_CART_TOTALS) {

    let total_amount = 0;
    let total_items = 0;
    state.cart.map((i) => {
      total_amount += i.amount * i.price;
      total_items += i.amount;
    })

    return { ...state, total_items, total_amount }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default cart_reducer
