import React from 'react'
import { useProductsContext } from '../context/products_context'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Error from './Error'
import Loading from './Loading'
import Product from './Product'
import back from '../assets/back.jpg'

const FeaturedProducts = () => {

  const { products_loading: loading, products_error: error, featured_products: featured } = useProductsContext();

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }
  return <Wrapper className='section'>
    <div className="title">
      <h2>NOUVEAUX PRODUITS</h2>
      <div className="underline"></div>
    </div>
    <div className="section-center featured">
      {

        featured.slice(0, 5).map((product_f) => {
          return <Product key={product_f._id} {...product_f} />
        })
      }
    </div>
  </Wrapper>
}

const Wrapper = styled.section`
  /* background: var(--clr-grey-10);
  background: hsl(345deg 85% 2%); */
  color: white;
  background-image: url(${back});
  background-size: 100%;
   footer {
    margin-top: 1rem;
    align-items: center;
    text-align: center;
    padding: 4%;
    background: #00000052;
    display: block;
    h5{
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .featured {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      height: 340px;
    }
  }
  .btn {
    display: block;
    width: 148px;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 576px) {
    .featured {
      /* grid-template-columns: repeat(auto-fit, minmax(360px, 1fr)); */
      grid-template-columns:repeat(auto-fit,minmax(10rem,1fr));
      
    }
  }
`

export default FeaturedProducts
