import React from 'react'
import styled from 'styled-components'
import { useFilterContext } from '../context/filter_context'
import { getUniqueValues, formatPrice, isWhite } from '../utils/helpers'
import { FaCheck } from 'react-icons/fa'

const Filters = () => {

  const { filters: { text, company, category, color, size, min_price, max_price, price, shipping }, updateFilters, clearFilters, filtered_product, all_products } = useFilterContext();


  let categories = getUniqueValues(all_products, 'category', 'name');
  categories = categories.filter(a => a.name === 'tous').concat(
    categories.filter(a => a.name !== 'tous').sort((x, y) => x.name.localeCompare(y.name))
  )
  // .concat(
  //   categories.sort((x, y) => x.name.localeCompare(y.name))
  // )
  const stockItems = getUniqueValues(all_products, 'stockItems', '_id', true).filter((i) => i.quantity > 0); // get colors with stock
  // const stockColors = getUniqueValues(stockItems, 'color_id', '_id'); // get colors with stock
  // console.log(stockItems);


  const colors_stock = stockItems.map((i) => i.color_id);
  const size_stock = stockItems.map((i) => i.size_id);

  // console.log(colors_stock);

  const colors = getUniqueValues(all_products, 'colors', '_id', true)
    .filter((i) => i._id === false || colors_stock.includes(i._id));


  const sizes = getUniqueValues(all_products, 'size', '_id', true).sort((x, y) => x.name - y.name)
    .filter((i) => (i._id === false || size_stock.includes(i._id)));
  const companies = getUniqueValues(all_products, 'company');
  // const companies = []; 
  // const colors = [];
  // const sizes = [];

  // console.log(sizes);
  return <Wrapper>
    <div className="content">
      <form onSubmit={(e) => { e.preventDefault() }}>
        {/* Search Field */}
        <div className="form-control">
          <input type="text" name="text" placeholder='Recherche' className='search-input' value={text} onChange={updateFilters} />
        </div>
        {/* End Search Field */}
        {/* Categories */}
        <div className="form-control">
          <h5>Catégories</h5>
          <div>
            {
              categories.map((cat, index) => {
                return <button key={index}
                  name="category"
                  value={cat?.name}
                  type='button'
                  className={`${category.toLowerCase() === cat?.name?.toLowerCase() ? 'active' : null}`}
                  onClick={updateFilters}
                >{cat?.name}</button>
              })
            }
          </div>
        </div>
        {/* Company */}
        <div className="form-control">
          <h5>Marques</h5>

          <select className='company form-select' name="company" value={company} onChange={updateFilters}>
            {
              companies.map((com, index) => {
                return <option key={index} value={com}>{com}</option>
              })
            }
          </select>
        </div>
        {/* Colors */}

        <div className="form-control">
          <h5>Couleurs</h5>
          <div className="colors">
            {

              colors.map((c, index) => {
                if (c.name === "tous")
                  return <button onClick={updateFilters} key={index} className={`all-btn ${color === 'tous' && 'active'}`} name='color' value="tous" >tous</button>
                return <button onClick={updateFilters} key={index} name="color" style={{ background: c.code }} value={c._id} className={`${color === c._id ? 'color-btn active' : 'color-btn'}`}>

                  {color === c._id && <FaCheck className={`${isWhite(c.code) ? "black-check" : null}`} />}
                </button>
              })
            }
          </div>
        </div>
        {/* Sizes */}
        <div className="form-control">
          <h5>Tailles</h5>
          <div className="colors">
            {

              sizes.map((c, index) => {
                if (c.name === "tous")
                  return <button onClick={updateFilters} key={index} className={`all-btn ${size === 'tous' && 'active'}`} name='size' value="tous" >tous</button>
                return <button onClick={updateFilters} key={index} name="size" value={c._id} className={`${size === c._id ? 'size-btn active' : 'size-btn'}`}>
                  {c.name}
                </button>
              })
            }
          </div>
        </div>
        {/* Prices */}
        <div className="form-control">
          <h5>Prix</h5>
          <p className='price'>{formatPrice(price)}</p>
          <input className='price_range' type="range" name="price" onChange={updateFilters} min={min_price} max={max_price} value={price} step="1" />
        </div>
        {/* Shipping */
          // <div className="form-control shipping">
          //   <label htmlFor='shipping'>Free Shipping</label>
          //   <input type="checkbox" name="shipping" id="shipping" onChange={updateFilters} checked={shipping} />
          // </div>
        }

        <button type='button' className='clear-btn' onClick={clearFilters}>
          effacer les filtres
        </button>
      </form>
    </div>
  </Wrapper>
}

const Wrapper = styled.section`
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
  .price_range{
    width: 100%;
  }
  .search-input {
    padding: 0.5rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    letter-spacing: var(--spacing);
    width: 100%;
  }
  .search-input::placeholder {
    text-transform: capitalize;
  }

  button {
    display: block;
    margin: 0.25em 0;
    padding: 0.25rem 0;
    text-transform: capitalize;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
    cursor: pointer;
  }
  .active {
    border-color: var(--clr-grey-5);
  }
  .company {
    background-color: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    padding: 0.25rem;
  }
  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
  .black-check {
    color: #000000 !important;
  }
  .color-btn {
    display: inline-block;
    border: 1px solid var(--clr-grey-7);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  
  .size-btn {
    display: inline-block;
    padding: 0 1rem;
    font-size: 0.8rem;
    min-width: 1.5rem;
    height: 1.5rem;
    border-radius: 17%;
    background: var(--clr-white);
    margin-right: 0.5rem;
    border: 1px solid var(--clr-grey-7);
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.75rem;
      color: var(--clr-white);
    }
  }
  button.size-btn.active {
    border: 2px solid black;
    background: white;
    color: black;
  }
  .all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .all-btn .active {
    text-decoration: underline;
  }
  .price {
    margin-bottom: 0.25rem;
  }
  .shipping {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    text-transform: capitalize;
    column-gap: 0.5rem;
    font-size: 1rem;
  }
  .clear-btn {
    background: var(--clr-red-dark);
    color: var(--clr-white);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }
  @media (min-width: 768px) {
    .content {
      position: sticky;
      top: 1rem;
    }
  }
`

export default Filters
