import React from 'react'
import styled from 'styled-components'
import { formatPrice } from '../utils/helpers'
import { Link } from 'react-router-dom'
import { API_URL } from '../utils/constants'
import { useFilterContext } from '../context/filter_context'
import ProductListView from './ProductListView'
const ListView = ({ products }) => {

  const { filters: { color } } = useFilterContext();

  return <Wrapper>
    {products.map((product) => {
      // const { _id, name, image, price, description, colors } = product;

      return <ProductListView  key={product._id} {...product} />
    })}
  </Wrapper>

   
}

const Wrapper = styled.section`
  display: grid;
  row-gap: 3rem;

  img {
    width: 100%;
    display: block;
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: var(--radius);
    margin-bottom: 1rem;
  }
  h4 {
    margin-bottom: 0.5rem;
  }
  .price {
    color: var(--clr-primary-5);
    margin-bottom: 0.75rem;
  }
  p {
    max-width: 45em;
    margin-bottom: 1rem;
  }
  .btn {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  
  .colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
 
  .color-btn {
    display: inline-block;
    border: 1px solid var(--clr-grey-7);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 992px) {
    article {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 2rem;
      align-items: center;
    }
  }
`

export default ListView
