import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useProductsContext } from '../context/products_context'
import { API_URL } from '../utils/constants'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import blur from './../assets/blur.png'

const HomeImage = () => {


  const { settings } = useProductsContext();
  const [homeImage, setHomeImage] = useState(false);

  useEffect(() => {
    if (settings.image)
      setHomeImage(settings.image);

  }, [settings])

  return <Wrapper>

    {
      homeImage && <div>
        <Link to="/products">
          <LazyLoadImage wrapperClassName="gallery-img-wrapper" className='img-responsive' effect="blur" src={`${API_URL}${homeImage}`} alt={settings?.title} />

          {/* <img alt={settings?.title} src={`${API_URL}${homeImage}`} className="img-responsive" /> */}
          {/* <div className="img-responsive" style={{ backgroundImage: `url(${API_URL}${homeImage})` }} /> */}
        </Link>
      </div>
    }
  </Wrapper>
}

const Wrapper = styled.div`
 .gallery-img-wrapper{
    display: block !important;
  }
.img-responsive{
  display:block ;
  height:auto ;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}
 
`

export default HomeImage
