import React, { useEffect } from 'react'
import styled from 'styled-components'
import logo from '../assets/logo.png'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { API_URL, links } from '../utils/constants'
import CartButtons from './CartButtons'
import { useProductsContext } from '../context/products_context'
import { useCartContext } from '../context/cart_context'
import ReactPixel from 'react-facebook-pixel';
import HelmetMeta from './HelmetMeta'

const Nav = () => {
  const { openSidebar, settings } = useProductsContext();
  const { cart } = useCartContext()

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };


  useEffect(() => {
    if (settings?.fbpixel) {
      ReactPixel.init(`${settings?.fbpixel}`, options);
      ReactPixel.pageView();
    }

  }, [settings])



  return <NavContainer>


    <HelmetMeta title={settings.title} url={settings.url} description={settings.description} image={`${API_URL}${settings.logo || logo}`} type="website" />

    <div className='nav-center'>
      <div className='nav-header'>
        <Link to="/">
          <img src={`${API_URL}${settings.logo || logo}`} alt={settings.name || "Shop"} />
        </Link>
        <button type='button' className='nav-toggle' onClick={openSidebar}>
          <FaBars />
        </button>
      </div>
      <ul className='nav-links'>
        {links.map((link) => {
          const { id, text, url } = link;
          return (<li key={id}><Link to={url}>{text}</Link></li>)
        })}
        {
          cart.length > 0 &&
          (<li>
            <Link to="/checkout" >Commande</Link>
          </li>)
        }
      </ul>
      <CartButtons />
    </div>

  </NavContainer>
}

const NavContainer = styled.nav`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 9px 16px 20px 8px #ffc8d652;

  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      max-width: 142px;
      margin-left: 0px;
      max-height: 64px;
    }
  }
  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
  }
  .cart-btn-wrapper {
    display: none;
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 1rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid var(--clr-primary-7);
        }
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`

export default Nav
