import React from 'react'
import { GiAlarmClock, GiLobArrow, GiHeadphones } from 'react-icons/gi'

// dotenv.config();
// console.log(process.env.NODE_ENV) // remove this after you've confirmed it working

export const links = [
  {
    id: 1,
    text: 'Accueil',
    url: '/',
  },
  // {
  //   id: 2,
  //   text: 'A propos',
  //   url: '/about',
  // },
  {
    id: 3,
    text: 'Produits',
    url: '/products',
  }

]

export const services = [
  {
    id: 1,
    icon: <GiAlarmClock />,
    title: 'Livraison en 24h',
    text:
      'Lorem ipsum, dolor sit amet consectvelit autem unde numquam nisi',
  },
  {
    id: 2,
    icon: <GiLobArrow />,
    title: 'Retour 100% gratuit',
    text:
      'Lorem ipsum, dolor sit amet consectetur  unde numquam nisi',
  },
  {
    id: 3,
    icon: <GiHeadphones />,
    title: 'Service client 7/7    ',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates',
  },
]

// export const API_URL = "https://apilinashoes.ig60.com";
export const API_URL = process.env.NODE_ENV === "development" ? "" : "https://apilinashoes.ig60.com";

export const settings_url = `${API_URL}/api/settings`

export const products_url = `${API_URL}/api/products/public`
export const orders_url = `${API_URL}/api/orders`

export const single_product_url = `${API_URL}/api/products/`

// export const products_url = 'https://course-api.com/react-store-products'

// export const single_product_url = `https://course-api.com/react-store-single-product?id=`
