import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useProductsContext } from '../context/products_context'
import { API_URL, single_product_url as url } from '../utils/constants'
import { formatPrice } from '../utils/helpers'
import {
  Loading,
  Error,
  ProductImages,
  AddToCart,
  Stars,
  PageHero,
} from '../components'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel';
import HelmetMeta from '../components/HelmetMeta'

const SingleProductPage = () => {

  const { id } = useParams();
  const history = useNavigate();
  const { single_product_loading: loading, single_product_error: error, single_product: product, fetchSingleProduct } = useProductsContext();
  console.log(id);

  useEffect(() => {

    fetchSingleProduct(`${url}${id}`)

    ReactPixel.track("ViewContent", {
      content_type: 'product',
      content_ids: [id],
      value: 0.5,
      currency: 'TND',
    });

  }, [id])

  useEffect(() => {
    if (error) {
      setInterval(() => {
        history('/products');
      }, 3000)
    }
  }, [error])

  // console.log(product);
  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const { name, price, description, stock, stars, reviews, _id: sku, company, images, image, stockItems } = product;

  return <Wrapper>




    <HelmetMeta title={name} url={window.location.href} description={description} image={`${API_URL}${image}`} price={price} product_id={sku} type="product" />


    <PageHero title={name} product />
    <div className="section section-center page">
      <Link to="/products" className='btn'>Retour aux produits</Link>
      <div className="product-center">
        <ProductImages images={images} stockItems={stockItems} />

        <section className='content'>
          <h2>{name}</h2>
          <Stars stars={stars} reviews={reviews} />
          <h5 className='price'>{formatPrice(price)}</h5>
          <p className='desc'>{description}</p>
          <p className="info">
            <span>Disponibilité : </span>
            {stock > 0 ? 'En Stock' : 'En rupture de stock'}
          </p>
          <p className="info">
            <span>SKU : </span>
            {sku}
          </p>
          <p className="info">
            <span>Marque : </span>
            {company}
          </p>
          <hr />
          {<AddToCart product={product} />}
        </section>
      </div>
    </div>
  </Wrapper>
}

const Wrapper = styled.main`
  .product-center {
    display: grid;
    gap: 4rem;
    margin-top: 2rem;
  }
  .price {
    color: var(--clr-primary-5);
  }
  .desc {
    line-height: 2;
    max-width: 45em;
  }
  .info {
    text-transform: capitalize;
    width: 300px;
    display: grid;
    grid-template-columns: 125px 1fr;
    span {
      font-weight: 700;
    }
  }

  @media (min-width: 992px) {
    .product-center {
      grid-template-columns: 1fr 1fr;
      align-items: top;
    }
    .price {
      font-size: 1.25rem;
    }
  }
`

export default SingleProductPage
