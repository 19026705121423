import React, { useEffect, useState } from 'react'
import { FeaturedProducts, Hero, Services, Contact } from '../components'
import HomeImage from '../components/HomeImage';
import { useProductsContext } from '../context/products_context';

const HomePage = () => {




  return (
    <main>
      <HomeImage />
      <Hero />
      <FeaturedProducts />
      <Services />
      <Contact />
    </main>
  );

}

export default HomePage
